<template>
  <button
    class="sl__button previous-button"
    :aria-label="buttonLabel + ' button'"
    @click="previous"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-chevron-left"
    >
      <polyline points="15 18 9 12 15 6" />
    </svg>
    {{ buttonLabel }}
  </button>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PreviousButton',
  props: {
    buttonLabel: {
      type: String,
      required: false,
      default: 'previous',
    },
  },
  methods: {
    ...mapActions('stagelineCreateCompany', [
      'setPreviousSlide',
    ]),
    previous() {
      this.setPreviousSlide()
    },
  },
}
</script>

<style lang="scss" scoped>
.previous-button {
  height: 3.0em;
  border: 0;
  border-radius: 0.25em;
  color: $sl__teal1;
  background: white;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}
</style>
