var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "sl__button previous-button",
      attrs: { "aria-label": _vm.buttonLabel + " button" },
      on: { click: _vm.previous },
    },
    [
      _c(
        "svg",
        {
          staticClass: "feather feather-chevron-left",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            fill: "none",
            stroke: "currentColor",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        },
        [_c("polyline", { attrs: { points: "15 18 9 12 15 6" } })]
      ),
      _vm._v("\n  " + _vm._s(_vm.buttonLabel) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }